import { Controller } from "stimulus";

export default class extends Controller {
  selectNewCard() {
    this.removeCardsBorder();
    this.newCardContainerTarget.classList.add("border-green-brand");
    this.newCardInputTarget.checked = true;
    this.newCardDetailsTarget.classList.remove("hidden");
  }

  removeCardsBorder() {
    this.paymentMethodContainerTargets.forEach(function (element) {
      element.classList.remove("border-green-brand");
    });
  }

  selectExitingCard(event) {
    this.removeCardsBorder();
    event.currentTarget.classList.add("border-green-brand");
    this.newCardDetailsTarget.classList.add("hidden");
    if (this.hasSaveCardTarget) {
      this.saveCardTarget.checked = false;
    }
  }

  get selectedPaymentCard() {
    return document.querySelector('input[name="payment_method"]:checked').value;
  }
}
