import Rails from "@rails/ujs";
window.Rails = Rails;
Rails.start();
require("turbolinks").start();

require.context("../fonts/icomoon");
import _ from "underscore";
import NProgress from "nprogress";
window.NProgress = NProgress;
window._ = _;

import "bootstrap5/js/dist/alert";
import "bootstrap5/js/dist/button";
import "bootstrap5/js/dist/collapse";
import "bootstrap5/js/dist/dropdown";
import "bootstrap5/js/dist/tab";
import "bootstrap5/js/dist/scrollspy";
import "bootstrap";
import "chart.js";
import "chartkick";
import "ekko-lightbox";
import $ from "jquery";
import application from "./shared_application";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import ContentLoaderController from "../src/controllers/content_loader_controller";
import PlacesController from "../src/controllers/places_controller";
import FormController from "../src/controllers/form_controller";
import DropdownController from "../src/controllers/dropdown_controller";
import select2 from "./components/select2.js";
import V2Pagination from "../src/controllers/v2_pagination_controller";
import { Dropdown, Tabs } from "tailwindcss-stimulus-components";
import ButtonToggle from "../src/public/controllers/toggle_controller";
import { Pagination } from "swiper";
import "../src/app_stimulus";
import StimulusFlatpickr from "stimulus-flatpickr";
import SelectConditionalField from "../src/controllers/select_conditional_field_controller";
import MappedinController from "../src/controllers/mappedin_conroller";

const context = require.context("../src/public/controllers", true, /^(?!.*gallery_controller\.js).*\.js/);
application.load(definitionsFromContext(context));

// recheck usage later
import PublicGalleryController from "../src/public/controllers/gallery_controller";
application.register("public-gallery", PublicGalleryController);

application.register("content-loader", ContentLoaderController);
application.register("places", PlacesController);
application.register("form", FormController);
application.register("dropdown", DropdownController);
application.register("v2-pagination", V2Pagination);
application.register("tailwind-dropdown", Dropdown);
application.register("tabs", Tabs);
application.register("toggle", ButtonToggle);
application.register("flatpickr", StimulusFlatpickr);
application.register("select-conditional-field", SelectConditionalField);
application.register("mappedin", MappedinController);

var onReady = function () {
  select2();
  $("[data-toggle=popover]").popover({
    html: true,
    content: function () {
      return $(".listing-page-popover-content").html();
    },
  });
};

window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events");
  event.initEvent("google-maps-callback", true, true);
  event.args = args;
  window.dispatchEvent(event);
};

global.$ = $;

$(document).on("turbolinks:load", function () {
  onReady();
});

$(document).on("click", '[data-toggle="lightbox"]', function (event) {
  event.preventDefault();
  $(this).ekkoLightbox({
    alwaysShowClose: false,
  });
});

// Handles AJAX responses for remote Rails forms
//
// Provides the selector for the element you want to replace with the response
// in the 'render-target' response header.
const handleAjaxResponse = (e) => {
  if ([400, 500].includes(e.detail[2].status)) return;

  const renderTarget = e.detail[2].getResponseHeader('render-target');
  const responseBody = e.detail[0].body;

  Turbolinks.clearCache();

  // Handle the response body by replacing the target element or updating the body
  if (renderTarget) {
    const dstEl = document.querySelector(renderTarget);
    const srcEl = responseBody.querySelector(renderTarget);
    if (srcEl && dstEl) {
      dstEl.innerHTML = srcEl.innerHTML;
    }
  } else {
    document.body.innerHTML = responseBody.innerHTML;
  }

  Turbolinks.dispatch("turbolinks:load");
};

document.addEventListener("ajax:error", handleAjaxResponse);
document.addEventListener("ajax:success", handleAjaxResponse);
