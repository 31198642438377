import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        const unit_modal = $('#modal__unit_user_add');
        const inviteButton = $('.invite-user-btn')

        $(document).ready(() => {
            if (unit_modal.length) {
                if (inviteButton.length) {
                    unit_modal.modal('hide');
                    inviteButton[0].click();
                }
            }
        });
    }
}