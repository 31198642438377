import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = { id: String, componentAction: String };

    connect() {
        let id = this.idValue;
        let replacementElement = document.getElementById(`brand-${id}`);

        const targetElement = replacementElement.querySelector('[data-remote-modal-target]');

        if (targetElement) {
            targetElement.textContent = this.componentActionValue;
        }
    }
}
