import { Controller } from 'stimulus'

//
// Hide/show input based on selected option
//

export default class extends Controller {
    static targets = ["select", "input", "inputWrapper", "secondInputWrapper"];

    static values = {
        showOption: String
    }

    connect() {
        this.toggle()
    }

    toggle(event) {
        if (this.selectTarget.value == this.showOptionValue) {
            this.inputWrapperTarget.classList.remove('hidden');
            if (this.hasSecondInputWrapperTarget) {
                this.secondInputWrapperTarget.classList.add('hidden');
            }
            this.inputTarget.disabled = false;
        } else {
            this.inputWrapperTarget.classList.add('hidden');
            if (this.hasSecondInputWrapperTarget) {
                this.secondInputWrapperTarget.classList.remove('hidden');
            }
            this.inputTarget.disabled = true;
        }
    }
}
