import { Controller } from "stimulus";
import $ from "jquery";
import getCsrfToken from "../utils/csrf";

export default class extends Controller {
  connect() {
    const exportUrl = this.data.get("url");

    const startDateFromField = document.getElementById("export_start_date_from");
    const startDateToField = document.getElementById("export_start_date_to");
    const endDateFromField = document.getElementById("export_end_date_from");
    const endDateToField = document.getElementById("export_end_date_to");
    const bookingStatusField = document.getElementById("export_status");
    const listingIdsField = document.getElementById("export_listing_ids");
    const includeArchivedField = document.getElementById("export_include_archived_true");
    const modalDialog = $("#modal__export");
    const exportForm = document.querySelector("#modal__export.modal__booking-export .export-form");
    const successStubElement = document.querySelector("#modal__export.modal__booking-export .success-stub");

    modalDialog.on("hidden.bs.modal", () => {
      successStubElement.classList.add("is-hidden");
      exportForm.classList.remove("is-hidden");
    });

    $("#modal__export .submit-btn").on("click", (e) => {
      const exportFormat = e.target.dataset.format;
      fetch(`${exportUrl}.${exportFormat}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getCsrfToken(),
        },
        body: JSON.stringify({
          export_filters: {
            start_date: {
              from: startDateFromField.value,
              to: startDateToField.value,
            },
            end_date: {
              from: endDateFromField.value,
              to: endDateToField.value,
            },
          },
          search: {
            status: bookingStatusField.value,
            listing_ids: Array.from(listingIdsField.selectedOptions, (el) => el.value),
            include_archived: includeArchivedField.checked,
          },
        }),
      }).then(() => {
        successStubElement.classList.remove("is-hidden");
        exportForm.classList.add("is-hidden");
      });
    });

    successStubElement.querySelector(".success-icon").addEventListener("click", () => {
      successStubElement.classList.add("is-hidden");
      exportForm.classList.remove("is-hidden");
    });
  }
}
