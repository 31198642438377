import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["loadMore", "container"];
  static values = { moreBrandsUrl: String, resetBrandUrl: String, hasMoreBrands: Boolean, unitId: Number };

  connect() {
    this.nextBrandPage = 1;
    this.hasMoreBrands = this.hasMoreBrandsValue;
    this.unitId = this.unitIdValue;
  }

  toggleBorder(event) {
    let labels = document.querySelectorAll(`label[for=${event.currentTarget.id}]`);
    labels.forEach((label) => {
      label.classList.toggle("border-green-brand");
      label.classList.toggle("border-gray-800");
      if (label.dataset.urlValue) {
        let url = label.dataset.urlValue;
        $.ajax({ url });
      }
    });
  }

  loadUnitTemplates(unitId) {
    const that = this;
    this.unitId = unitId;
    this.nextBrandPage = 0;
    this.hasMoreBrands = true;
    this.containerTarget
      .querySelectorAll('input[name="booking[brand_id]"]:checked')
      .forEach((el) => (el.checked = false));
    this.containerTarget.querySelectorAll("label.choose-template").forEach((el) => el.remove());

    $.ajax({ url: this.resetBrandUrlValue }).done(() => {
      that.showMoreTemplates();
    });
  }

  showMoreTemplates() {
    if (!this.hasMoreBrands) return;

    const url = this.moreBrandsUrlValue + `?unit_id=${this.unitId}&page=${this.nextBrandPage}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response error");
        } else {
          this.nextBrandPage += 1;
        }
        return response.text();
      })
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const contentElements = Array.from(doc.body.children);

        // There should be a better way to check if we have more brands to load.
        // Especially, considering that user can change selected unit. TODO.
        if (contentElements.length < 8) {
          this.loadMoreTarget.classList.add("hidden");
        } else if (this.nextBrandPage === 1) {
          this.loadMoreTarget.classList.remove("hidden");
        }

        const currentLabels = this.containerTarget.querySelectorAll("label.js-project-template");
        if (currentLabels.length > 0) {
          const lastLabel = currentLabels[currentLabels.length - 1];
          const referenceNode = lastLabel.nextElementSibling;

          contentElements.forEach((child) => {
            this.containerTarget.insertBefore(child.cloneNode(true), referenceNode);
          });
        } else {
          const fragment = document.createDocumentFragment();
          contentElements.forEach((child) => fragment.appendChild(child.cloneNode(true)));

          this.containerTarget.insertBefore(fragment, this.containerTarget.firstChild);
        }
      })
      .catch((error) => {
        console.error("Error loading HTML:", error);
      });
  }
}
