/**
 * Collapse
 *
 * https://medium.com/dailyjs/mimicking-bootstraps-collapse-with-vanilla-javascript-b3bb389040e7
 */

export default function collapse() {
  window.addEventListener('click', function (event) {
    const element = event.target;
    const triggers = Array.from(document.querySelectorAll('[data-toggle="collapse"]'));
    if (triggers.includes(element)) {
      event.preventDefault();
      const selector = element.getAttribute('data-target');
      const selectorTextClosed = element.getAttribute('data-text-closed');
      const selectorTextOpened = element.getAttribute('data-text-opened');
      collapse(selector, 'toggle');
      if (event.target.classList.contains('collapse-trigger--opened')) {
        event.target.classList.remove('collapse-trigger--opened');
        event.target.innerHTML = selectorTextClosed;
        toggleWrapper(element);
      } else {
        event.target.classList.add('collapse-trigger--opened');
        event.target.innerHTML = selectorTextOpened;
        toggleWrapper(element);

        const collapseAreaSelector = element.getAttribute('data-collapse-area');
        if (collapseAreaSelector) {
          const elementsInArea = Array.from(document.querySelectorAll(`${collapseAreaSelector} [data-toggle="collapse"]`));
          elementsInArea.forEach(el => {
            collapse(el.getAttribute('data-target'), 'hide');
            el.classList.remove('collapse-trigger--opened');
            if (el !== element) el.classList.add('collapsed');
            el.innerHTML = el.getAttribute('data-text-closed');
          })
        }
      }
    }
  }, false);

  const fnmap = {
    'toggle': 'toggle',
    'show': 'add',
    'hide': 'remove'
  };

  const collapse = (selector, cmd) => {
    const targets = Array.from(document.querySelectorAll(selector));
    targets.forEach(target => {
      target.classList[fnmap[cmd]]('show');
    });
  }

  const toggleWrapper = element => {
    const collapseAreaSelector = element.getAttribute('data-collapse-area');
    const sectionWrapperSelector = element.getAttribute('data-section-wrapper-area');
    const sectionSelectedWrapperSelector = element.getAttribute('data-section-selected-wrapper-area');
    if (!sectionWrapperSelector || !collapseAreaSelector) return;

    const wrappersInArea = Array.from(document.querySelectorAll(`${collapseAreaSelector} ${sectionWrapperSelector}`));
    const currentWrapper = element.closest(".accordion-section-wrapper");
    wrappersInArea.forEach(wrapper => {
      if (sectionSelectedWrapperSelector !== null) {
        const currentWrapper = document.querySelector(sectionSelectedWrapperSelector).closest(".accordion-section-wrapper");

        if (wrapper === currentWrapper) {
          if (wrapper.classList.contains("collapsed")) {
            wrapper.classList.remove("collapsed");

            setTimeout(() => {
              wrapper.scrollIntoView({behavior: 'smooth', block: 'start'});
              wrapper.firstElementChild.classList.remove("collapsed");
            }, 100);
          } else {
            wrapper.classList.add("collapsed");
          }
        } else {
          wrapper.classList.add("collapsed");
        }
      }
      else {
        if (wrapper === currentWrapper) {
          if (wrapper.classList.contains("collapsed")) {
            wrapper.classList.remove("collapsed");

            setTimeout(() => {
              wrapper.scrollIntoView({behavior: 'smooth', block: 'start'});
              wrapper.firstElementChild.classList.remove("collapsed");
            }, 100);
          } else {
            wrapper.classList.add("collapsed");
          }
        } else {
          wrapper.classList.add("collapsed");
        }
      }
    })
  }
}
