import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const buttons = document.querySelectorAll(".space-alert-remove-button")

    buttons.forEach(button => {
      button.addEventListener("click", this.handleClick.bind(this))
    })
  }

  handleClick(event) {
    this.updateFormAction(event)
    this.updateFormTitle(event)
  }

  updateFormAction(event) {
    const formPath = event.currentTarget.getAttribute("data-space-alert-remove-path")
    document.getElementById("space-alert-remove-form").action = formPath
  }

  updateFormTitle(event) {
    const title = event.currentTarget.getAttribute("data-space-alert-remove-modal-title");
    const modalTitle = document.getElementById("space-alert-remove-modal-title");

    if (modalTitle) {
      modalTitle.textContent = title;
    }
  }
}
