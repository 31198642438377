import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["latitude", "longitude"];

    connect() {
        this.mapboxAccessToken = this.element.dataset.eventMapAccessToken;
        this.addedAutosuggestListeners();

        document.addEventListener("autosuggestPlaceChanged", (event) => {
            const modalElement = document.getElementById("events-add-new-modal");

            if (!this.isModalVisible(modalElement)) {
                this.triggerEnterKeyOnSearch();
            }
        });
    }

    isModalVisible(modalElement) {
        return modalElement && modalElement.classList.contains("show");
    }

    triggerEnterKeyOnSearch() {
        const element = document.getElementById("events-address-search");
        if (element) {
            const event = new KeyboardEvent("keydown", {
                key: "Enter",
                keyCode: 13,
                code: "Enter",
                which: 13,
                bubbles: true,
                cancelable: true,
            });

            element.dispatchEvent(event);
        }
    }

    disconnect() {
        if (this.hasLatitudeTarget) {
            this.latitudeTarget.removeEventListener("change", this.updateMapAttributes);
        }
        if (this.hasLongitudeTarget) {
            this.longitudeTarget.removeEventListener("change", this.updateMapAttributes);
        }
    }

    addedAutosuggestListeners() {
        if (this.hasLatitudeTarget && this.hasLongitudeTarget) {
            this.updateMapAttributes = this.updateMapAttributes.bind(this);

            this.latitudeTarget.addEventListener("change", this.updateMapAttributes);
            this.longitudeTarget.addEventListener("change", this.updateMapAttributes);
        }
    }

    updateMapAttributes() {
        const latitude = this.latitudeTarget.value;
        const longitude = this.longitudeTarget.value;

        if (latitude && longitude) {
            document.getElementById('map').innerHTML = `
                    <div id="map" class="map" data-controller="events">
                      <div class="relative w-full" style="height: 20rem !important" id='location-map'>
                        <div data-controller='map'
                             data-map-target='map'
                             data-map-access-token="${this.mapboxAccessToken}"
                             class="h-80 w-full rounded absolute w-full inset-y-0"
                             data-lat="${latitude}"
                             data-lng="${longitude}">
                        </div>
                      </div>
                    </div>
            `;
        }
    }
}
