import { Controller } from "stimulus"

export default class ValidationController extends Controller {
  static targets = ["buildingForm", "titleFields", "descriptionFields"]

  connect() {
    this.initFormListeners();
  }

  initFormListeners() {
    if (document.querySelector('.submit-button-wrapper')) {
      document.querySelector('.submit-button-wrapper').addEventListener('click', () => {
        this.checkDefaultLocaleFields.bind(this)();
      });
    }

    document.querySelector('#building_form .submit-button').addEventListener('click', this.checkFiles.bind(this));
    document.getElementById('building_building_default_locale').addEventListener('change', this.updateRequiredFields.bind(this))

    let allTitleFields = document.querySelectorAll(`.property_title input`)
    let allDescriptionFields = document.querySelectorAll(`.property_description textarea`)

    allTitleFields.forEach(field => {
      field.addEventListener('keyup', this.updateTitleField.bind(this))
    })

    allDescriptionFields.forEach(field => {
      field.addEventListener('keyup', this.updateDescriptionField.bind(this))
    })
  }

  checkFiles(event) {
    const files = document.querySelector('.files .template-download');
    const photosError = document.querySelector('.description-form').dataset.photoserror;
    if (!files) {
      alert(photosError);
      event.preventDefault();
    }
  }

  updateRequiredFields(event) {
    const defaultLocale = event.target.value;
    let allTitleFields = document.querySelectorAll(`.property_title input`)
    let allDescriptionFields = document.querySelectorAll(`.property_description textarea`)

    allTitleFields.forEach(field => {
      field.removeAttribute("required")
    })
    allDescriptionFields.forEach(field => {
      field.removeAttribute("required")
    })

    const titleFieldId = `building_building_title_${defaultLocale}`;
    const descriptionFieldId = `building_building_description_${defaultLocale}`;

    document.getElementById(titleFieldId).setAttribute("required", "required")
    document.getElementById(descriptionFieldId).setAttribute("required", "required")

    this.clearErrorMessages();
    this.checkDefaultLocaleFields();
  }

  updateTitleField(event) {
    const defaultLocale = document.getElementById('building_building_default_locale').value;
    const titleFieldId = `building_building_title_${defaultLocale}`;
    if (event.target.id === titleFieldId && event.target.value !== '') {
      this.clearErrorMessagesForField('title');
    }
    this.checkDefaultLocaleFields()
  }

  updateDescriptionField(event) {
    const defaultLocale = document.getElementById('building_building_default_locale').value;
    const descriptionFieldId = `building_building_description_${defaultLocale}`;
    if (event.target.id === descriptionFieldId && event.target.value !== '') {
      this.clearErrorMessagesForField('description');
    }
    this.checkDefaultLocaleFields()
  }

  clearErrorMessages() {
    document.querySelector('.property_title .input__error_container .input__error')?.remove();
    document.querySelector('.property_description .input__error_container .input__error')?.remove();
    this.titleFieldsTargets.forEach(field => {
      field.classList.remove('input__danger')
    })
    this.descriptionFieldsTargets.forEach(field => {
      field.classList.remove('input__danger')
    })
  }

  clearErrorMessagesForField(type) {
    const container = type === 'title' ? '.property_title .input__error_container' : '.property_description .input__error_container';
    document.querySelector(`${container} .input__error`)?.remove();
    if (type === 'title') {
      this.titleFieldsTargets.forEach(field => {
        field.classList.remove('input__danger')
      })
    } else {
      this.descriptionFieldsTargets.forEach(field => {
        field.classList.remove('input__danger')
      })
    }
  }

  checkDefaultLocaleFields() {
    const defaultLocale = document.getElementById('building_building_default_locale').value;
    const titleFieldId = `building_building_title_${defaultLocale}`;
    const descriptionFieldId = `building_building_description_${defaultLocale}`;
    const titleError = document.querySelector('.description-form').dataset.titleerror;
    const descriptionError = document.querySelector('.description-form').dataset.descriptionerror;

    if (document.getElementById(titleFieldId).value === '' && document.querySelector(`.property_title .input__error_container .input__error`) == null) {
      this.addErrorToField('title', titleError);
  }

    if (document.getElementById(descriptionFieldId).value === '' && document.querySelector(`.property_description .input__error_container .input__error`) == null) {
      this.addErrorToField('description', descriptionError);
    }
  }

  addErrorToField(type, message) {
    const container = type === 'title' ? '.property_title .input__error_container' : '.property_description .input__error_container';
    const fields = type === 'title' ? this.titleFieldsTargets : this.descriptionFieldsTargets;

    const errorDiv = document.createElement('div');
    errorDiv.className = 'input__error';
    errorDiv.innerText = message;
    document.querySelector(container).appendChild(errorDiv);

    fields.forEach(field => {
      field.classList.add('input__danger')
    })
  }
}
