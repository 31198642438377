import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "badge"];

  toggleForm() {
    this.formTarget.classList.toggle("hidden");
    this.badgeTarget.classList.toggle("hidden");
  }
}
