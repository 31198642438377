import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "modal",
  ];

  connect() {
    this.initializeControls();
    if (this.shouldOpenModal()) {
      this.openModal();
    }
  }

  initializeControls() {
    const sendButton = document.querySelector('#as_guest_form .send-btn');
    sendButton?.addEventListener("click", () => {
      this.sendAsGuest();
    });
  }

  shouldOpenModal() {
    return window.location.search.includes('modal_message_sent=true')
  }

  openModal() {
    $('#modal__message_sent').modal('show');

    setTimeout(function() {
      window.history.replaceState({}, document.title,window.location.href.split("?")[0]);
    },0);
  }

  sendAsGuest() {
    event.preventDefault();
    let form = $('#as_guest_form');
    let action = form.attr('data-action');
    let method = form.attr('data-method');
    let data = form.serialize();

    $.ajax({
      url: action,
      type: method,
      dataType: "json",
      data: {
        mailbox_message: data
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      beforeSend: function() {
        document.querySelector('.loader')?.classList.remove('is-hidden');

        const requiredFields = [
          { selector: '#first_name', errorId: 'first-name-error' },
          { selector: '#last_name', errorId: 'last-name-error' },
          { selector: '#organization', errorId: 'organization-error' },
          { selector: '#email', errorId: 'email-error' },
          { selector: '#phone', errorId: 'phone-error' },
          { selector: '#reason', errorId: 'reason-error' },
          { selector: '#viewing_schedule_datetime', errorId: 'viewing-schedule-datetime-error' },
          { selector: 'input[name="mailbox_message[from]"]', errorId: 'dates-error' },
          { selector: 'input[name="mailbox_message[to]"]', errorId: 'dates-error' },
          { selector: '#subject', errorId: 'subject-error' },
          { selector: '#concept', errorId: 'concept-error' },
          { selector: '#message', errorId: 'body-error' }
        ];

        let isValid = true;

        requiredFields.forEach(field => {
          const errorElement = document.getElementById(field.errorId);
          if (errorElement) {
            errorElement.textContent = '';
            errorElement.classList.add = 'is-hidden';
          }
        });

        const reasonValue = document.querySelector('#reason').value;

        requiredFields.forEach(field => {
          const value = document.querySelector(field.selector).value.trim();

          if (field.selector === '#viewing_schedule_datetime' && reasonValue !== 'schedule_viewing') {
            return;
          }

          if ((field.selector === 'input[name="mailbox_message[from]"]' || field.selector === 'input[name="mailbox_message[to]"]') && reasonValue === 'schedule_viewing') {
            return;
          }

          if (!value) {
            isValid = false;
            const sendButton = document.querySelector('#as_guest_form .send-btn');
            const errorElement = document.getElementById(field.errorId);
            const errorMessage = sendButton.dataset["errorMessage"];
            if (errorElement) {
              errorElement.textContent = errorMessage;
              errorElement.classList.remove('is-hidden');
            }
          }
        });

        if (!isValid) {
          return false;
        }
      }
    }).done(() => {
      document.querySelector('.loader')?.classList.add('is-hidden')

      const formFields = document.querySelectorAll('#modal__as_guest .field input, #modal__as_guest .field textarea')
      formFields.forEach(field => {
        field.value = '';
      })
      $('select#concept').select2()

      let asGuestModal = '#modal__as_guest'
      let verifyEmailModal = '#modal__verify_email'
      $(asGuestModal).modal('hide');
      $(verifyEmailModal).modal('show');

      const signInButton = document.querySelector('.sign_in_btn');
      if (signInButton) {
        signInButton.classList.add('is-hidden')
      }
    }).fail((data) => {
      const errors = data?.responseJSON?.errors;

      for (const field in errors) {
        if (errors.hasOwnProperty(field)) {
          if (!errors[field]) continue;
          const errorMessages = Array.isArray(errors[field]) ? errors[field] : [errors[field]];
          const errorMessage = errorMessages.join(', ');
          $('#' + field + '-error').text(errorMessage).show();
        }
      }

      document.querySelector('.loader')?.classList.add('is-hidden')

      if (data?.responseJSON?.errors?.text === 'Sign in') {
        const signInButton = document.querySelector('.sign_in_btn');
        if (signInButton) {
          signInButton.classList.add('is-hidden')
        }
        signInButton.classList.remove('is-hidden')
      }
    })
  }
}
