import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["arrow", "arrowDown"];

  connect() {}

  toggleIncludeFees() {
    this.arrowDownTarget.classList.toggle("text-green-brand");
    this.arrowDownTarget.classList.toggle("text-gray-600");
  }
}
