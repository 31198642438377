import { Controller } from 'stimulus'
import $ from "jquery";

import { uploadDone } from "../events/upload";

export default class extends Controller {
  static targets = ["input", "preview", "uploadLink", "updateLinks", "previewIcon", "loader", "TypeErrorTextHolder"];

  connect() {
    const url = this.data.get('url');
    const imageTypes = /(\.|\/)(gif|jpe?g|png|bmp|tiff|webp)$/i;
    const formDataMethod = JSON.parse(this.data.get('form-data'));

    const $el = $(this.inputTarget);
    const $preview = $(this.previewTarget);
    const $loader = $(this.loaderTarget);

    $el.fileupload({
      url: url,
      uploadTemplateId: null,
      downloadTemplateId: null,
      dataType: 'json',
      autoUpload: true,
      maxNumberOfFiles: 1,
      acceptFileTypes: imageTypes,
      maxFileSize: 25000000 // 25 MB
    }).bind('fileuploadstart', () => {
      $loader.show();
      setTimeout(() => {
        $loader.children().width(360);
      }, 300);
    }).bind('fileuploadadd', (e, data) => {
        const files = data.files;
        let invalidTypesFiles = [];

        files.forEach(file => {
            if (!imageTypes.test(file.name)) {
              invalidTypesFiles.push(file.name);
            }
        });

        if (invalidTypesFiles.length > 0) {
            $(this.TypeErrorTextHolderTarget).removeClass("is-hidden");
            return false;
        }
        else {
          $(this.TypeErrorTextHolderTarget).addClass("is-hidden");
        }
    }).bind('fileuploaddone', (e, data) => {
      $loader.hide();
      const fileUrl = data.response().result.files[0].url;
      $preview.css("background", `url(${fileUrl})`).removeClass("empty");
      if (this.hasUploadLinkTarget) {
        $(this.uploadLinkTarget).hide();
      }
      if (this.hasUpdateLinksTarget) {
        $(this.updateLinksTarget).show();
      }
      if (this.hasPreviewIconTarget) {
        this.previewIconTarget.remove();
      }
      uploadDone();
    }).bind('fileuploadsubmit', (e, data) => {
      data.formData = formDataMethod;
    }).bind('fileuploadfail', () => {
      $loader.hide();
    });
  }

  removeUploadedImage() {
    $.ajax({
      url: this.data.get('url'),
      type: 'PUT',
      dataType: 'json',
      // change logo to attribute name from logo_uploader_component.rb
      data: { logo: null }
    }).done(() => {
      $(this.previewTarget).css("background", "").addClass("empty");
      $(this.uploadLinkTarget).show();
      $(this.updateLinksTarget).hide();
    });
  }
}