import { Controller } from "stimulus";

export default class extends Controller {
  connect() {}

  loadProjectTemplates(event) {
    const unitId = event.target.value;
    this.chooseProjectController.loadUnitTemplates(unitId);
  }

  get chooseProjectController() {
    return this.application.controllers.find((c) => c.identifier === "choose-project");
  }
}
