import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        const select = this.element.querySelector('select[name="city_slug"]');

        if (select) {
            select.addEventListener('change', (event) => {
                const newCitySlug = event.target.value;
                this.updateQueryParameterAndVisit('city_slug', newCitySlug);
            });
        }
    }

    updateQueryParameterAndVisit(param, value) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        params.set(param, value);

        const searchInput = document.querySelector('input[name="search[term]"]');
        if (searchInput && searchInput.value) {
            params.set('search[term]', searchInput.value);
        }

        url.search = params.toString();
        Turbolinks.visit(url.toString());
    }
}
