import { Controller } from "stimulus"
import Autonumeric from "autonumeric";

export default class extends Controller {
    static autonumericInputs

    connect() {
        this.initAutonumericInputs();
        this.toggleEmailNotification();
        this.initFootfallSwitcher();
        this.initSwitchListener();
    }

    getBuildingData() {
        return JSON.parse(document.querySelector('input[name="building_data"]').value);
    }

    initSwitchListener() {
        const switchElements = document.querySelectorAll('.custom-switch.is-active');

        switchElements.forEach(switchElement => {
            switchElement.addEventListener('click', (event) => this.handleSwitchChange(event.target));
        });
    }

    handleSwitchChange(target) {
        const buildingComplete = target.closest('form').dataset.buildingComplete === 'true';
        const header = document.querySelector('.manager__header');

        if (!buildingComplete) {
            this.showNotification(header);
            this.highlightRequiredFields();
        }
    }

    showNotification(header) {
        this.removeExistingNotification();

        const buildingData = this.getBuildingData();

        if (!buildingData.is_active && (!buildingData.description || buildingData.address == null ||
            buildingData.address === '' || buildingData.images.length === 0) || buildingData.channels.length === 0 && buildingData.for_marketplace !== true) {
            const messageBlock = this.createMessageBlock(buildingData.notification_text);
            header.after(messageBlock);
        }
    }

    removeExistingNotification() {
        const existingMessageBlock = document.querySelector('.building-notification');
        if (existingMessageBlock) {
            existingMessageBlock.remove();
        }
    }

    createMessageBlock(notificationText) {
        const messageBlock = document.createElement('div');
        messageBlock.className = 'building-notification flex justify-between items-center m-8 mb-0 px-8 py-4 text-sm font-semibold rounded notification-line warning';
        messageBlock.textContent = notificationText;

        const closeIcon = this.createCloseIcon(messageBlock);
        messageBlock.appendChild(closeIcon);

        return messageBlock;
    }

    createCloseIcon(messageBlock) {
        const closeIcon = document.createElement('i');
        closeIcon.className = 'icon-close-light text-xs cursor-pointer bold';
        closeIcon.style.fontSize = '10px';

        closeIcon.addEventListener('click', () => messageBlock.remove());

        return closeIcon;
    }

    highlightRequiredFields() {
        const buildingData = this.getBuildingData();

        if (!buildingData.is_active) {
            this.checkRequiredField('.card__description .card__title', buildingData.description);
            this.checkRequiredField('.card__location .card__title', buildingData.address !== null);
            this.checkRequiredField('.card__location .card__title', buildingData.address !== '');
            this.checkRequiredField('.card__photos .card__title', buildingData.images.length > 0);
            this.checkRequiredField('.card__channels .card__title', buildingData.channels.length > 0 && buildingData.for_marketplace === true);
        }
    }

    checkRequiredField(selector, condition) {
        if (!condition) {
            document.querySelector(selector).style.color = '#EC1B40';
        }
    }

    initFootfallSwitcher() {
        const footfallPerWeek = document.querySelector("[name='building_building[footfall_per_week]']");
        const footfallPerYear = document.querySelector("[name='building_building[footfall_per_year]']");
        const switcher = document.querySelector("[name='building_building[footfall_period]']");
        (footfallPerYear?.parentNode?.parentNode?.style ?? {}).gridRowGap = "0";

        switcher?.addEventListener("change", () => {
            switch (switcher.value) {
                case "week":
                    footfallPerYear.classList.add("is-hidden");
                    footfallPerWeek.classList.remove("is-hidden");
                    footfallPerWeek.value = footfallPerYear.value;
                    footfallPerYear.value = null;
                    break;
                case "year":
                    footfallPerWeek.classList.add("is-hidden");
                    footfallPerYear.classList.remove("is-hidden");
                    footfallPerYear.value = footfallPerWeek.value;
                    footfallPerWeek.value = null;
                    break;
            }
        });
    }

    initAutonumericInputs() {
        const currency = this.element.dataset.currency.toUpperCase();
        const decimalMark = this.element.dataset.decimalMark;
        const thousandsSeparator = this.element.dataset.thousandsSeparator;

        this.autonumericInputs = new Autonumeric.multiple(".currency-symbol", {
            digitGroupSeparator: thousandsSeparator,
            decimalCharacter: decimalMark,
            currencySymbolPlacement: Autonumeric.options.currencySymbolPlacement.prefix,
            currencySymbol: `${currency} `,
            minimumValue: 0,
            decimalPlaces: 0
        });

        const currencySwitcher = document.getElementById("building_building_currency");
        currencySwitcher?.addEventListener("change", () => {
            const newCurrency = currencySwitcher.value.toUpperCase();
            this.autonumericInputs.forEach(input => input.update({ currencySymbol: `${newCurrency} ` }));
        })
    }

    toggleEmailNotification() {
        const emailInput = document.getElementById("building_email");
        if (emailInput === null) {
            return
        }
        const isNotificationEnabled = document.getElementById("building_building_enable_notification_true").checked;
        const emailAddressField = emailInput.parentNode.parentNode;

        if (isNotificationEnabled) {
            emailAddressField.style.display = "grid";
        } else {
            emailAddressField.style.display = "none";
            emailInput.value = null;
        }
    }
}
