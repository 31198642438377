import {ApplicationController} from "stimulus-use";
import collapse from "../components/collapse";
import smoothScroll from "../components/smooth-scroll";
import toggleElement from "../components/toggle-element";

export default class extends ApplicationController {
    connect() {
        collapse();
        smoothScroll();
        toggleElement();
    }
}
