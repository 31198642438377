import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    openModal() {
        this.resetModal();
    }

    onChange() {
        this.resetModal();
    }

    resetModal() {
        const errorContainer = document.querySelector('.modal__user-unit .user-email-field .input__error_container');
        if (errorContainer) {
            errorContainer.innerHTML = "";
        }
        const emailInput = document.querySelector('.modal__user-unit #email');
        if (emailInput) {
            emailInput.value = "";
        }
        const rolesElement = document.querySelector('#unit_modal_roles');
        if (rolesElement) {
            rolesElement.remove();
        }
    }
}
