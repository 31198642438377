import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["emailError", "emailInput", "successModalContainer", "saveRecord", "initialModalClose", "buttonValue"];
  static values = {
    backdropColor: { type: String, default: 'rgba(0, 0, 0, 0.8)' },
    restoreScroll: { type: Boolean, default: true }
  }

  async validateEmail() {
    const parsleyInstance = $("#save-form").parsley();
    const target = parsleyInstance.$element.find('[id="email"]');

    if (!target.length) {
      return true;
    }

    target.parsley().removeError('email');
    const email = target.val();
    const url = target[0].dataset['savesearchValidateEmailUrlValue'];
    const errorTarget = this.emailErrorTarget;

    if (!email) {
      errorTarget.classList.add('hidden');
      return true;
    }

    try {
      const response = await fetch(`${url}?email=${encodeURIComponent(email)}`, {
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      });
      const data = await response.json();
      
      if (data["error"]) {
        target.parsley().removeError('email');
        target.parsley().addError('email', { message: data["error"], updateClass: true });
        target[0]?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        target[0]?.focus();
        return false;
      } else {
        target.parsley().removeError('email');
        return true;
      }
    } catch (error) {
      console.error("Email validation failed:", error);
      return false;
    }
  }

  async openSuccessModal() {
    let validForm = $("#save-form").parsley().validate();
    let validEmail = await this.validateEmail();

    if (validEmail && validForm) {
      this.successModalContainerTarget.classList.remove(this.toggleClass);
      this.initialModalCloseTarget.click();
      $(this.saveRecordTarget).trigger('submit.rails');
      
      if (!this.data.get("disable-backdrop")) {
        document.body.insertAdjacentHTML('beforeend', this.backgroundHtml);
        this.background = document.querySelector(`#${this.backgroundId}`);
      }
    }
  }

  skipValidation() {
    this.buttonValueTarget.value = 'sign-in';
    $(this.saveRecordTarget).parsley().destroy();
    $(this.saveRecordTarget).trigger('submit.rails');
  }

  removeBackground() {
    if (this.background) { this.background.remove(); }
  }

  _backgroundHTML() {
    return `<div id="${this.backgroundId}" class="fixed top-0 left-0 w-full h-full" style="background-color: ${this.backdropColorValue}; z-index: 9998;"></div>`;
  }

  removeSticky() {
    $('.search-section').removeClass('sticky');
  }

  addSticky() {
    $('.search-section').addClass('sticky');
  }

  connect() {
    this.backgroundId = 'modal-background';
    this.backgroundHtml = this._backgroundHTML();
    this.toggleClass = 'hidden';
  }
}
