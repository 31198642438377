import { Controller } from "stimulus";
import $ from "jquery";
import slider from "jquery-ui/ui/widgets/slider";
import "jquery-ui/themes/base/all.css";
import "jquery-ui-touch-punch";

export default class extends Controller {
  static targets = [
    "slider",
    "min",
    "max",
    "chart",
    "toggle",
    "placeholder",
    "minHolder",
    "maxHolder",
    "minLabel",
    "maxLabel",
    "clear",
    "container",
  ];
  static values = { placeholder: String, type: String, thousandsSeparator: String };

  connect() {
    this.search_slider();
    this.showPreview();
  }

  get galleryController() {
    return this.application.controllers.find((c) => c.identifier === "gallery");
  }

  search_slider() {
    const data = this.element.dataset;
    const { min, max, defaultMax, defaultMin, symbol } = data;
    const {
      minTarget,
      maxTarget,
      placeholderTarget,
      minHolderTarget,
      maxHolderTarget,
      minLabelTarget,
      maxLabelTarget,
      chartTarget,
      hasChartTarget,
      sliderTarget,
      containerTarget,
    } = this;
    const that = this;
    const options = {
      range: true,
      min: parseInt(min),
      max: max,
      values: [parseInt(defaultMin), parseInt(defaultMax)],
      slide: function (event, ui) {
        const newMin = ui.values[0];
        const newMax = ui.values[1];
        if (hasChartTarget) {
          const chart = Chartkick.charts[chartTarget.children[0].id];
          const chartData = chart.data.map((d) => d.data).flat();
          const selected = { data: chartData.filter((d) => d[0] <= newMax && d[0] >= newMin) };
          const others = { data: chartData.filter((d) => !(d[0] <= newMax && d[0] >= newMin)) };
          const updatedData = [selected, others];
          chart.updateData(updatedData);
        }
        minTarget.value = newMin;
        maxTarget.value = newMax;

        placeholderTarget.innerText = that.formatNumber(newMin) + "-" + that.formatNumber(newMax) + " " + symbol;
        if (that.typeValue === "price") {
          minHolderTarget.value = symbol + " " + newMin;
          maxHolderTarget.value = symbol + " " + newMax;
        } else {
          minHolderTarget.value = newMin + " " + symbol;
          maxHolderTarget.value = newMax + " " + symbol;
        }

        minLabelTarget.innerText = minHolderTarget.value;
        maxLabelTarget.innerText = maxHolderTarget.value;
        containerTarget.classList.remove("border-gray-300");
        containerTarget.classList.add("border-green-brand");
        containerTarget.classList.add("!border-2");
        placeholderTarget.classList.add("!text-green-brand");
      },
      change: function () {
        that.galleryController.load();
      },
    };
    slider(options, this.sliderTarget);
    minTarget.value = parseInt(defaultMin);
    maxTarget.value = parseInt(defaultMax);
    if (this.typeValue === "price") {
      minHolderTarget.value = symbol + " " + defaultMin;
      maxHolderTarget.value = symbol + " " + defaultMax;
    } else {
      minHolderTarget.value = defaultMin + " " + symbol;
      maxHolderTarget.value = defaultMax + " " + symbol;
    }

    minLabelTarget.innerText = minHolderTarget.value;
    maxLabelTarget.innerText = maxHolderTarget.value;
  }

  showPreview() {
    const data = this.element.dataset;
    const { min, max, defaultMin, defaultMax, symbol } = data;
    const { placeholderTarget, containerTarget } = this;

    if (parseInt(min) === parseInt(defaultMin) && parseInt(max) === parseInt(defaultMax)) {
      return;
    }

    placeholderTarget.innerText = this.formatNumber(defaultMin) + "-" + this.formatNumber(defaultMax) + " " + symbol;
    placeholderTarget.classList.add("!text-green-brand");

    containerTarget.classList.remove("border-gray-300");
    containerTarget.classList.add("border-green-brand");
    containerTarget.classList.add("!border-2");
  }

  reset() {
    const data = this.element.dataset;
    const symbol = data.symbol;

    const { min, max, defaultMax, defaultMin, type } = data;

    const sliders_range = this.sliderTarget.getElementsByClassName("ui-slider-range")[0];
    sliders_range.style.left = "0%";
    sliders_range.style.width = "100%";
    const sliders = this.sliderTarget.getElementsByClassName("ui-slider-handle");

    sliders[0].style.left = "0%";
    sliders[1].style.left = "100%";

    if (this.hasChartTarget) {
      const chart = Chartkick.charts[this.chartTarget.children[0].id];
      const chartData = chart.data.map((d) => d.data).flat();
      const selected = { data: chartData.filter((d) => d[0] <= defaultMax && d[0] >= defaultMin) };
      const others = { data: chartData.filter((d) => !(d[0] <= defaultMax && d[0] >= defaultMin)) };
      const updatedData = [selected, others];
      chart.updateData(updatedData);
    }

    // this.placeholderTarget.innerText = defaultMin + "-" + defaultMax + " " + symbol
    this.minHolderTarget.value = symbol + " " + min;
    this.maxHolderTarget.value = symbol + " " + max;

    this.minTarget.value = parseInt(defaultMin);
    this.maxTarget.value = parseInt(defaultMax);

    $(this.sliderTarget).slider("values", [min, max]);
    // this.galleryController.load()

    this.minLabelTarget.innerText = this.minHolderTarget.value;
    this.maxLabelTarget.innerText = this.maxHolderTarget.value;

    // change stylings
    this.placeholderTarget.innerText = this.placeholderValue;

    this.containerTarget.classList.add("border-gray-300");
    this.containerTarget.classList.remove("border-green-brand");
    this.containerTarget.classList.remove("!border-2");
    this.containerTarget.setAttribute("data-toggle-open-value", false);

    this.placeholderTarget.classList.remove("!text-green-brand");
  }

  formatNumber(value) {
    return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparatorValue);
  }
}
