import { Controller } from "stimulus";
import $ from "jquery";
import _ from "underscore";

export default class extends Controller {
  static targets = ["sidebar"];

  connect() {
    this.onResize = _.debounce(this.onResize.bind(this), 100);
    window.addEventListener("resize", this.onResize, false);

    this.observeModal();
  }

  observeModal() {
    let modal = document.getElementById("modal__message_sent");
    let sidebar = document.getElementById("right-sidebar");

    if (!modal || !sidebar) return;

    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.attributeName === "class") {
          if (modal.classList.contains("show")) {
            sidebar.style.zIndex = "998";
          }
        }
      }
    });

    observer.observe(modal, { attributes: true, attributeFilter: ["class"] });
  }

  onResize() {
    if ($(window).width() > this.maxWidth) {
      if ($(this.sidebarTarget).hasClass("show")) {
        $(this.sidebarTarget).modal("hide");
        $(".modal-backdrop").hide();
      }

      $(this.sidebarTarget).removeAttr("style");
    }
  }

  get maxWidth() {
    return parseInt(this.data.get('maxWidth'));
  }
}
