import { Controller } from "@hotwired/stimulus";
import getCsrfToken from "../utils/csrf";

export default class extends Controller {
  static targets = ["userNotification"];

  close() {
    const notificationId = this.element.dataset.userNotificationId;
    this.markAsRead(notificationId);

    this.userNotificationTarget.classList.add("hidden");
  }

  async markAsRead(notificationId) {
    try {
      const response = await fetch(`/user_notifications/${notificationId}/mark_as_read`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getCsrfToken()
        },
      });

      if (response.ok) {
        console.log("Notification marked as read");
      } else {
        console.error("Failed to mark notification as read");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
}
