import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    if (this.hasUrlValue) {
      this.fetchComponentHTML(this.urlValue)
    }
  }

  async fetchComponentHTML(url) {
    try {
      const response = await fetch(url, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      })
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`)

      const html = await response.text()
      this.element.innerHTML = html
    } catch (error) {
      console.error("Error fetching component:", error)
      this.element.innerHTML = "<p class='hidden'>Could not load component</p>"
    }
  }
}
