import { Controller } from 'stimulus'
import $ from "jquery";

import "blueimp-tmpl/js/tmpl"
import * as loadImage from "blueimp-load-image/js/load-image"
import "blueimp-canvas-to-blob/js/canvas-to-blob"
//import "blueimp-file-upload/js/vendor/jquery.ui.widget.js";
import "blueimp-file-upload/js/jquery.fileupload.js";
import "blueimp-file-upload/js/jquery.fileupload-ui.js";
import "blueimp-file-upload/js/jquery.fileupload-process.js";
import "blueimp-file-upload/js/jquery.iframe-transport"
// import "blueimp-file-upload/js/jquery.fileupload-image.js";
// import "blueimp-file-upload/js/jquery.fileupload-video.js";
// import "blueimp-file-upload/js/jquery.fileupload-audio.js";
import "blueimp-file-upload/js/jquery.fileupload-validate.js";

import { uploadDone } from "../events/upload";

export default class extends Controller {
    static targets = ["input", "TypeErrorTextHolder"];


    connect() {
        const name = this.data.get('name');
        const url = this.data.get('url');
        const uploadDeleteUrl = this.data.get('delete-url');
        // const type = this.data.get('type');
        const $el = $(this.inputTarget);

        let fileTypes;
        if (this.data.scope.element.dataset.uploadRestrictToImages === "true") {
            fileTypes = /\.(jpe?g|png|gif|bmp|tiff|webp)$/i;
        } else {
            fileTypes = /.*/;
        }

        let fp = $el.fileupload({
            url: url,
            uploadTemplateId: name + '-template-upload',
            downloadTemplateId: name + '-template-download',
            dataType: 'json',
            autoUpload: false,
            acceptFileTypes: fileTypes,
            // minWidth: 900,
            // minHeight: 600,
            maxFileSize: 25000000, // 25 MB
            // Enable image resizing, except for Android and Opera,
            // which actually support image resizing, but fail to
            // send Blob objects via XHR requests:
            dropZone: $el,
            disableImageResize: /Android(?!.*Chrome)|Opera/.test(window.navigator.userAgent),
            // previewMaxWidth: 320,
            // previewMaxHeight: 200,
            // previewMinWidth: 320,
            // previewMinHeight: 200,
            // previewCrop: true,
            // imageMaxHeight:3840,
            // imageMaxWidth:3840
            //disableExif: true
        }).bind('fileuploadadd', (e, data) => {
            const files = data.files;
            let invalidTypesFiles = [];

            files.forEach(file => {
                if (!fileTypes.test(file.name)) {
                    invalidTypesFiles.push(file.name);
                }
            });

            if (invalidTypesFiles.length > 0) {
                $(this.TypeErrorTextHolderTarget).removeClass("is-hidden");
                return false;
            }
            else {
                $(this.TypeErrorTextHolderTarget).addClass("is-hidden");
            }}).bind('fileuploaddone', function(e, data) {
            uploadDone();
        }).bind('fileuploadsubmit', function(e, data) {
            //
            // When the upload component is put inside a form, all data from that form is submitted;
            // this causes problems when `_method` is present because it affects the requests made
            // here; bellow we're replacing all form data, in case we need to submit additional data
            // (like in v1) we need to fix it.
            data.formData = { '_method': 'POST' };

            const attachmentElements = document.querySelectorAll(".listing-files tr");
            const position = Array.prototype.indexOf.call(attachmentElements, data.context[0]);
            if (position > -1) data.formData.position = position;

            return true;
        });

        this.loadExistingFiles({ uploadDeleteUrl: uploadDeleteUrl });
        // $el.addClass('fileupload-initialized');

        // document.addEventListener("turbolinks:before-cache", () => {
        //     this.unmount();
        // }, {once: true});
    }

    loadExistingFiles(data = {}) {
        const $el = $(this.inputTarget);

        //console.log('load existing fiels...');
        //console.log($el.fileupload('option', 'url'));
        $el.addClass('fileupload-processing');

        $.ajax({
            // Uncomment the following to send cross-domain cookies:
            //xhrFields: {withCredentials: true},
            url: $el.fileupload('option', 'url'),
            dataType: 'json',
            context: $el[0]
        }).always(function () {
            $el.removeClass('fileupload-processing');
        }).done(function (result) {
            try {
                if (data.uploadDeleteUrl && result?.files?.length) {
                    result.files.forEach(f => {
                        if (f?.deleteUrl == null || f.deleteUrl === '' ) {
                            f.deleteUrl = data.uploadDeleteUrl.replace("#id", f.id);
                        }
                    });
                }
                $(this).fileupload('option', 'done').call(this, $.Event('done'), {result: result});
            } catch(e) {
                console.log('Error inside fileupload callback');
                console.log(e);
            }
        });
    }

    disconnect() {
    }

    unmount() {
        // const $el = $(this.inputTarget);
        // if ($el.hasClass('fileupload-initialized')) {
        //     $el.fileupload('destroy');
        // }
    }
}