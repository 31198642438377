import { Calendar } from "@fullcalendar/core";
import { initializeRadioButtons } from "../components/square-radio-button";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import Autonumeric from "autonumeric";
import _ from "underscore";
import { RRule } from "rrule";
import { t } from '../i18n/t';
import getCsrfToken from "../utils/csrf";
import "../../stylesheets/fullcalendar/fullcalendar.scss";

export function hasEventIntersaction(event, visibleEvents) {
  const eventStart = moment(event.start).startOf("day");
  const eventEnd = moment(event.end || event.start).endOf("day");

  return visibleEvents.some(visibleEvent => {
    const visibleEventStart = moment(toUTC(visibleEvent.start)).startOf("day");
    const visibleEventEnd = moment(toUTC(visibleEvent.end || visibleEvent.start)).endOf("day");

    if (typeof(event.id) != "undefined" && event.id === visibleEvent.id) return false;
    if (eventStart === visibleEventStart || eventStart === visibleEventEnd) return true;
    if (eventStart >= visibleEventStart && eventStart <= visibleEventEnd) return true;
    if (eventStart <= visibleEventStart && eventEnd >= visibleEventStart) return true;
  })
}

export function isEditableDate(date) {
  const restricted = document.querySelector(".date-ranges").getAttribute("data-date-range-min-date");
  if (!restricted) return true;

  return moment(date).startOf("day") >= moment().startOf("day");
}

function recalculateRecurrenceRule(event) {
  if (!event.extendedProps.recurrence_rule) return null;

  const parsedRule = RRule.fromString(event.extendedProps.recurrence_rule);
  const byweekday = parsedRule.options["byweekday"];
  const dtstart = moment.utc(event.start, momentDateFormat()).toDate();
  const until = moment.utc(event.end || event.start, momentDateFormat()).add(moment.duration("23:59:59")).toDate();

  return new RRule({
    freq: RRule.WEEKLY,
    dtstart: dtstart,
    until: until,
    byweekday: byweekday
  }).toString();
}

function buildRecurrenceRule() {
  const recurrenceType = document.getElementById("recurrence_type").getAttribute("data-value");
  if (recurrenceType !== "weekly") return null;

  const dayCheckboxes = Array.prototype.slice.call(document.getElementsByName("byweekday"));
  const byweekday = dayCheckboxes.filter(ch => ch.checked == true).map(ch => parseInt(ch.value));
  const dtstart = moment.utc(document.getElementById("starts_at").value, momentDateFormat()).toDate();
  const until = moment.utc(document.getElementById("ends_at").value, momentDateFormat())
                      .add(moment.duration("23:59:59"))
                      .toDate();

  return new RRule({
    freq: RRule.WEEKLY,
    dtstart: dtstart,
    until: until,
    byweekday: byweekday
  }).toString();
}

function fillWithRecurrenceRule(event, momentDateFormat) {
  let weekdays = [];
  if (event.recurrence_rule) {
    const parsedRule = RRule.fromString(event.recurrence_rule);
    weekdays = parsedRule.options["byweekday"];

    if (parsedRule.options["until"]) {
      document.getElementById("ends_at").value = moment(parsedRule.options["until"]).utc().format(momentDateFormat);
    }

    if (parsedRule.options["dtstart"]) {
      document.getElementById("starts_at").value =
        moment(parsedRule.options["dtstart"]).utc().format(momentDateFormat);
    }
  }

  _.range(7).forEach(i => {
    document.querySelectorAll(`[name=byweekday][value="${i}"]`).item(0).checked = _.contains(weekdays, i);
  });
}

function extractId(url) {
  const match = url.match(/\/listings\/(\d+)\//);
  return match ? match[1] : null;
}

function fillPrices(event) {
  const pricesSection = document.getElementById("special-prices");
  const current_listing_id = extractId(event.url);
  const listingCurrency = document.querySelector(`.listing_currency[data-id="${current_listing_id}"]`)?.getAttribute('data-currency');
  const currency = listingCurrency || event.currency || pricesSection.dataset.currency;
  const decimalMark = event.decimal_mark || pricesSection.dataset.a_dec;
  const thousandsSeparator = event.thousands_separator || pricesSection.dataset.a_sep;

  document.getElementById("rent_per_day").remove();
  const rentPerDay = document.createElement("input");
  rentPerDay.classList.add("form-control");
  rentPerDay.id = "rent_per_day";
  rentPerDay.setAttribute("type", "text");
  rentPerDay.setAttribute("name", "rent_per_day");
  document.getElementById("rent_per_day_group").appendChild(rentPerDay);
  const rentPerDayAuto = new Autonumeric("#rent_per_day", {
    digitGroupSeparator: thousandsSeparator,
    decimalCharacter: decimalMark,
    currencySymbolPlacement: Autonumeric.options.currencySymbolPlacement.prefix,
    currencySymbol: `${currency} `,
    minimumValue: 0
  });
  const pricePerDay = event.per_day || '';
  rentPerDay.value = pricePerDay;
  rentPerDayAuto.set(pricePerDay)

  document.getElementById("rent_per_week").remove();
  const rentPerWeek = document.createElement("input");
  rentPerWeek.classList.add("form-control");
  rentPerWeek.id = "rent_per_week";
  rentPerWeek.setAttribute("type", "text");
  rentPerWeek.setAttribute("name", "rent_per_week");
  document.getElementById("rent_per_week_group").appendChild(rentPerWeek);
  const rentPerWeekAuto = new Autonumeric("#rent_per_week", {
    digitGroupSeparator: thousandsSeparator,
    decimalCharacter: decimalMark,
    currencySymbolPlacement: Autonumeric.options.currencySymbolPlacement.prefix,
    currencySymbol: `${currency} `,
    minimumValue: 0
  });
  const pricePerWeek = event.per_week || '';
  rentPerWeek.value = pricePerWeek;
  rentPerWeekAuto.set(pricePerWeek);

  document.getElementById("rent_per_month").remove();
  const rentPerMonth = document.createElement("input");
  rentPerMonth.classList.add("form-control");
  rentPerMonth.id = "rent_per_month";
  rentPerMonth.setAttribute("type", "text");
  rentPerMonth.setAttribute("name", "rent_per_month");
  document.getElementById("rent_per_month_group").appendChild(rentPerMonth);
  const rentPerMonthAuto = new Autonumeric("#rent_per_month", {
    digitGroupSeparator: thousandsSeparator,
    decimalCharacter: decimalMark,
    currencySymbolPlacement: Autonumeric.options.currencySymbolPlacement.prefix,
    currencySymbol: `${currency} `,
    minimumValue: 0
  });
  const pricePerMonth = event.per_month || '';
  rentPerMonth.value = pricePerMonth;
  rentPerMonthAuto.set(pricePerMonth);

  document.getElementById("special_price_comment").remove();
  const specialPriceComment = document.createElement("textarea");
  specialPriceComment.classList.add("form-control");
  specialPriceComment.id = "special_price_comment";
  specialPriceComment.setAttribute("name", "special_price_comment");
  specialPriceComment.setAttribute("rows", 4);
  specialPriceComment.value = event.special_price_comment || "";
  document.getElementById("special_price_comment_group").appendChild(specialPriceComment);
}

export function squashEventFields(event) {
  return {
    id: event.id,
    start: toUTC(event.start),
    end: toUTC(event.end),
    title: event.title,
    url: event.url,
    type: event.extendedProps.type,
    start_date: event.extendedProps.start_date,
    end_date: event.extendedProps.end_date,
    cancellation_policy: event.extendedProps.cancellation_policy,
    listing_info: event.extendedProps.listing_info,
    booking_info: event.extendedProps.booking_info,
    conversation_link: event.extendedProps.conversation_link,
    organization_link: event.extendedProps.organization_link,
    organization_name: event.extendedProps.organization_name,
    building: event.extendedProps.building,
    invoices: event.extendedProps.invoices,
    is_available: event.extendedProps.is_available,
    recurrence_rule: event.extendedProps.recurrence_rule,
    per_day: event.extendedProps.per_day,
    per_week: event.extendedProps.per_week,
    per_month: event.extendedProps.per_month,
    decimal_mark: event.extendedProps.decimal_mark,
    thousands_separator: event.extendedProps.thousands_separator,
    currency: event.extendedProps.currency,
    special_price_comment: event.extendedProps.special_price_comment,
    notes: event.extendedProps.notes
  };
}

function initializeRadioSwitcher(switcher, section, showValue, onShow) {
  switcher.onchange = jsEvent => {
    if (jsEvent.target.getAttribute("data-value") === showValue) {
      section.removeAttribute("style");
      if (onShow) onShow();
      return;
    }
    section.setAttribute("style", "display: none")
  }
}

function disableModal() {
  const startSelect = document.getElementById("starts_at");
  const endSelect = document.getElementById("ends_at");
  const recurrenceSwitcher = document.getElementById("recurrence_type");
  const availabilitySwitcher = document.getElementById("is_available");
  const dayRentInput = document.getElementById("rent_per_day");
  const weekRentInput = document.getElementById("rent_per_week");
  const monthRentInput = document.getElementById("rent_per_month");
  const notesTextArea = document.getElementById("notes");
  const saveButton = document.getElementById("save");
  const dayCheckboxes = Array.from(document.getElementsByClassName("day-checkbox"));

  [startSelect, endSelect, recurrenceSwitcher, availabilitySwitcher, dayRentInput, weekRentInput,
    monthRentInput, notesTextArea, saveButton].concat(dayCheckboxes).forEach((element) => {
    element.setAttribute("disabled", "");
  });
}

function enableModal() {
  const startSelect = document.getElementById("starts_at");
  const endSelect = document.getElementById("ends_at");
  const recurrenceSwitcher = document.getElementById("recurrence_type");
  const availabilitySwitcher = document.getElementById("is_available");
  const dayRentInput = document.getElementById("rent_per_day");
  const weekRentInput = document.getElementById("rent_per_week");
  const monthRentInput = document.getElementById("rent_per_month");
  const notesTextArea = document.getElementById("notes");
  const saveButton = document.getElementById("save");
  const dayCheckboxes = Array.from(document.getElementsByClassName("day-checkbox"));

  [startSelect, endSelect, recurrenceSwitcher, availabilitySwitcher, dayRentInput, weekRentInput,
    monthRentInput, notesTextArea, saveButton].concat(dayCheckboxes).forEach(element => {
    element.removeAttribute("disabled");
  });
}

export function showModal(event, calendar) {
  if (event.type === "availability") showAvailabilityDialog(event, calendar, momentDateFormat());
  if (event.type === "booking") showBookingDialog(event);
}

function showBookingDialog(event) {
  $("#booking-modal").removeData("bs.modal");

  const listingLink = document.getElementById("listing-link");
  listingLink.setAttribute("href", event.listing_info.link);

  const listingImage = document.getElementById("listing-image");
  listingImage.setAttribute("style", `background-image: url(${event.listing_info.image_url})`);

  const bookingStatus = document.getElementById("booking-status");
  bookingStatus.classList.add(`booking-status-${event.booking_info.status}`);
  bookingStatus.innerText = event.booking_info.status_label;

  const organizationLink = document.getElementById("organization-link");
  organizationLink.setAttribute("href", event.organization_link);
  organizationLink.innerText = event.organization_name;

  const building = document.getElementById("building");
  const buildingTitle = document.getElementById("building-title");
  if (event.building) {
    building.classList.remove("is-hidden");
    buildingTitle.innerText = event.building;
  } else {
    building.classList.add("is-hidden");
    buildingTitle.innerText = "";
  }

  const conversationLink = document.getElementById("conversation-link");
  conversationLink.setAttribute("href", event.conversation_link);

  const bookingNum = document.getElementById("booking-num");
  bookingNum.innerText = event.booking_info.number;

  const bookingChannel = document.getElementById("booking-channel");
  bookingChannel.innerText = event.booking_info.channel;

  const popupDate = document.getElementById("booking-date-from");
  popupDate.innerText = event.start_date || null;

  const popdownDate = document.getElementById("booking-date-to");
  popdownDate.innerText = event.end_date || null;

  const cancellationPolicy = document.getElementById("cancellation-policy");
  cancellationPolicy.innerText = event.cancellation_policy || null;

  const invoicesHeader = document.getElementById("invoices-header");
  const invoices = document.getElementById("invoices");
  invoices.innerHTML = "";
  if (event.invoices) {
    invoicesHeader.classList.remove("is-hidden");
    invoices.classList.remove("is-hidden");

    event.invoices.forEach((invoice) => {
      addInvoiceItem(invoice);
    });
  } else {
    invoicesHeader.classList.add("is-hidden");
    invoices.classList.add("is-hidden");
  }

  $("#booking-modal").modal();
}

export function showAvailabilityDialog(event, calendar, momentDateFormat) {
  // clear modal dialog data after last opening
  // since we use jQuery modal dialog we still have to use jQuery functionality to clear data like this
  $("#availability-modal").removeData("bs.modal");

  const eventStart = moment(event.start).format(momentDateFormat);
  const startDateElem = document.getElementById("starts_at");
  startDateElem.value = eventStart;
  startDateElem.addEventListener("click", refreshDatePickerPosition);

  const eventEnd = event.end ? moment(event.end).format(momentDateFormat) : eventStart;
  const endDateElem = document.getElementById("ends_at");
  endDateElem.value = eventEnd;
  endDateElem.addEventListener("click", refreshDatePickerPosition);

  fillWithRecurrenceRule(event, momentDateFormat);

  const recurrenceSwitcher = document.getElementById("recurrence_type");
  const recurrenceValue = event.recurrence_rule ? "weekly" : "";
  const recurrenceOption = document.querySelector(`#recurrence_type .square-radio-button-option[data-value="${recurrenceValue}"]`);
  const recurrenceSection = document.getElementById("weekly-options");
  initializeRadioSwitcher(recurrenceSwitcher, recurrenceSection, "weekly", () => {
    // reset recurrence checkboxes "recurrence" section was opened and closed without save
    fillWithRecurrenceRule(event, momentDateFormat);
  });
  recurrenceOption.dispatchEvent(new Event("click"));

  fillPrices(event);

  const availabilitySwitcher = document.getElementById("is_available");
  const availabilityValue = event.is_available.toString();
  const availabilityOption = document.querySelector(`#is_available .square-radio-button-option[data-value="${availabilityValue}"]`);
  const pricesSection = document.getElementById("special-prices");
  initializeRadioSwitcher(availabilitySwitcher, pricesSection, "true", () => {
    // reset price inputs if "special prices" section was opened and closed without save
    fillPrices(event);
  });
  availabilityOption.dispatchEvent(new Event("click"));

  document.getElementById("notes").value = event.notes || "";
  document.getElementById("event-errors").innerHTML = "";
  document.getElementById("save").onclick = saveButtonClick(event, calendar);

  isEditableDate(event.end || event.start) ? enableModal() : disableModal();

  const modalDialog = $("#availability-modal");
  modalDialog.unbind();
  modalDialog.on("scroll", refreshDatePickerPosition);
  modalDialog.modal();
}

function addInvoiceItem(invoice) {
  const invoicesContainer = document.querySelector("#invoices");

  let showLink = "";
  if (invoice.show_link) {
    showLink = `<a href="javascript:void(0)" data-controller="remote-modal"
                                             data-action="remote-modal#inject"
                                             data-remote-modal-url="${invoice.show_link}">
                    <i class="icon-transactions"></i>
                </a>`
  }

  invoicesContainer.innerHTML += `<div class="invoice-item">
                                    <span class="title">${t("invoice.title", locale())} #${invoice.id}</span>
                                    ${showLink}
                                    <a href="${invoice.download_link}">
                                      <i class="icon-download"></i>
                                    </a>
                                    <div class="invoice__status badge ${invoice.status}-badge">
                                      ${invoice.label}
                                    </div>
                                  </div>`;
}

function saveButtonClick(event, calendar) {
  if (!isEditableDate(event)) return;

  return () => {
    fetch(event.url, {
      method: event.id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCsrfToken()
      },
      body: JSON.stringify({
        starts_at: document.getElementById("starts_at").value,
        ends_at: document.getElementById("ends_at").value,
        recurrence_rule: buildRecurrenceRule(),
        is_available: document.getElementById("is_available").getAttribute("data-value"),
        rent_per_day: document.getElementById("rent_per_day").value,
        rent_per_week: document.getElementById("rent_per_week").value,
        rent_per_month: document.getElementById("rent_per_month").value,
        special_price_comment: document.getElementById("special_price_comment").value,
        notes: document.getElementById("notes").value
      })
    }).then(response => response.json())
      .then(data => {
        if (data.errors) {
          Object.values(data.errors).forEach((message) => {
            showErrorMessage(message);
          })

          return
        }

        $("#availability-modal").modal("hide");
        calendar.refetchEvents();
      });
  }
}

function moveEvent(event, revert, calendar) {
  if (!isEditableDate(event.start) || hasEventIntersaction(event, calendar.getEvents())) {
    revert();
    return;
  }

  fetch(event.url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getCsrfToken()
    },
    body: JSON.stringify({
      starts_at: event.start,
      ends_at: event.end || event.start,
      recurrence_rule: recalculateRecurrenceRule(event)
    })
  }).then(response => response.json())
    .then(data => {
      if (data.errors) {
        revert();
        return;
      }

      event.setExtendedProp("recurrence_rule", data.extendedProps.recurrence_rule);
    });
}

function showErrorMessage(message) {
  const errorsContainer = document.getElementById("event-errors");
  const notifier = document.createElement("div");
  notifier.className = "alert alert-warning";
  notifier.innerHTML = `<button class="close" data-dismiss="alert">×</button>${message}`;
  errorsContainer.appendChild(notifier);
}

// HACK: force flatpickr position recalculation when it's off screen (the right part)
function refreshDatePickerPosition() {
  const flatpickr = document.querySelector(".flatpickr-calendar");

  window.dispatchEvent(new Event("resize"));
  if (flatpickr.style.right !== "auto") return;

  const timer = setInterval(() => {
    if (flatpickr.style.right === "auto") return;

    flatpickr.style.opacity = 1;
    clearTimeout(timer);
  }, 100);
}

function destroyEvent(event, calendar) {
  fetch(event.url, {
    method: "DELETE",
    headers: {
      "X-CSRF-Token": getCsrfToken()
    },
  }).then(() => {
    calendar.refetchEvents();
  });
}

function initializeCalendarHeader() {
  const calendarHeader = document.querySelector(".fc-header-toolbar.fc-toolbar.fc-toolbar-ltr");
  calendarHeader.setAttribute("style", "display: none");

  const buttonToday = document.querySelector(".fc-today-button.fc-button");
  const headerTitle = document.querySelector(".fc-toolbar-title");
  const buttonNext = document.querySelector(".fc-next-button.fc-button");
  const pageHeaderButtonNext = document.querySelector("#button_next");
  pageHeaderButtonNext.onclick = () => {
    buttonNext.dispatchEvent(new Event("click"));
    pageHeaderTitle.innerText = headerTitle.innerText;

    pageHeaderButtonToday.setAttribute("disabled", "disabled");
    if (buttonToday.getAttribute("disabled") === null) pageHeaderButtonToday.removeAttribute("disabled");
  }

  const buttonPrev = document.querySelector(".fc-prev-button.fc-button");
  const pageHeaderButtonPrev = document.querySelector("#button_previous");
  pageHeaderButtonPrev.onclick = () => {
    buttonPrev.dispatchEvent(new Event("click"));
    pageHeaderTitle.innerText = headerTitle.innerText;

    pageHeaderButtonToday.setAttribute("disabled", "disabled");
    if (buttonToday.getAttribute("disabled") === null) pageHeaderButtonToday.removeAttribute("disabled");
  }

  const pageHeaderButtonToday = document.querySelector("#button_today");
  const pageHeaderTitle = document.querySelector("#toolbar_title");
  pageHeaderButtonToday.onclick = () => {
    buttonToday.dispatchEvent(new Event("click"));
    pageHeaderTitle.innerText = headerTitle.innerText;

    pageHeaderButtonToday.setAttribute("disabled", "disabled");
    if (buttonToday.getAttribute("disabled") === null) pageHeaderButtonToday.removeAttribute("disabled");
  }

  const listingAvailabilityLabel = $('#listing_availability');
  listingAvailabilityLabel.tooltip({ html: true });
}

function momentDateFormat() {
  return t("date.formats.moment", locale())
}

export function locale() {
  const calendarEl = document.getElementById("calendar") || document.getElementById("scheduler");
  return calendarEl.getAttribute("data-locale") || 'en';
}

export function toUTC(date) {
  if (!date) return date;

  return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
}

export function fullcalendar() {
  const calendarEl = document.getElementById("calendar");
  if (!calendarEl) return;

  const calendar = new Calendar(calendarEl, {
    plugins: [dayGridPlugin, interactionPlugin],
    timeZone: 'UTC',
    height: 'auto',
    events: calendarEl.dataset.url,
    firstDay: 1,
    selectable: false,
    editable: true,
    eventStartEditable: true,
    eventDurationEditable: true,
    locale: locale().slice(0, 2),
    headerToolbar: {
      left: 'title,today',
      right: 'prev,next'
    },
    buttonText: {
      today: t('scheduler.today', locale())
    },
    dateClick: ({ date }) => {
      const utcDate = toUTC(date);
      if (!isEditableDate(utcDate)) return;

      const visibleEvents = calendar.getEvents();
      if (hasEventIntersaction({ start: utcDate, end: utcDate }, visibleEvents)) return;

      const eventForm = document.getElementById("event-form");
      const url = eventForm.getAttribute("action");
      showModal({
        type: "availability",
        start: utcDate,
        end: utcDate,
        url: url,
        is_available: false
      }, calendar);
    },
    eventClick: ({ event, jsEvent }) => {
      jsEvent.preventDefault();

      if (jsEvent.target.classList.contains("calendar-event-delete")) {
        jsEvent.stopPropagation();
        destroyEvent(event, calendar);
        return
      }

      if (jsEvent.target.classList.contains("calendar-event") || jsEvent.target.classList.contains("fc-event")) {
        jsEvent.stopPropagation();
        showModal(squashEventFields(event), calendar);
        return
      }

      // by some reason event propagation is stopped by default
      // so we have to emit click event by hand for parent Node
      jsEvent.target.parentNode.click();
    },
    eventDrop: ({event, revert}) => {
      moveEvent(event, revert, calendar);
    },
    eventResize: ({event, revert}) => {
      moveEvent(event, revert, calendar);
    },
    eventContent: ({ event }, createElement) => {
      let calendarEventContent = [createElement(
        "div",
        { class: "calendar-event-title", title: event.extendedProps.tooltip },
        event.title
      )];

      const approvedBooking = event.classNames.includes("availability--booking-approved");
      if (approvedBooking) {
        calendarEventContent.push(createElement(
          "i",
          {
            class: "icon-checkmark-circle"
          }
        ));
      }

      const pendingBooking = event.classNames.includes("availability--booking-pending");
      const offeredBooking = event.classNames.includes("availability--booking-offered");
      if (pendingBooking || offeredBooking) {
        calendarEventContent.push(createElement(
          "i",
          {
            class: "icon-clock"
          }
        ));
      }

      if (isEditableDate(event.end || event.start) && event.url !== "null") {
        calendarEventContent.push(createElement(
          "button",
          {
            class: "calendar-event-delete close"
          },
          createElement("i", { class: "icon-close-light" }, null)
        ));
      }

      return createElement(
        "div",
        { class: "calendar-event" },
        calendarEventContent
      );
    }
  });
  calendar.render();

  initializeRadioButtons();
  initializeCalendarHeader();
}
