import { Controller } from "stimulus";
import getCsrfToken from "../utils/csrf";
import { initializeRadioButtons } from "../components/square-radio-button";
import { useDispatch } from "stimulus-use";
import moment from "moment/moment";
import { t } from "../i18n/t";
import $ from "jquery";

export default class extends Controller {
  static targets = ["modal", "dateHint", "channelHint", "dateRange", "from", "to", "price"];
  static listingsFetchTimer;
  static validationTimer;
  static nextStepTimer;

  connect() {
    useDispatch(this);
    initializeRadioButtons();
    this.initializeOfferTypeSection();
    this.initializeListingSection();
    this.initializeControls();
    this.startValidationMonitor();
  }

  initializeOfferTypeSection() {
    if (!this.differentTypesAllowed) {
      return;
    }

    const offerTypeInput = document.getElementById("offer_type");
    const offerTypeOptions = document.querySelectorAll("label.offer_type_option");

    offerTypeOptions.forEach((option) => {
      option.addEventListener("click", () => {
        const type = option.querySelector('input[name="offer[type]"]').value;

        offerTypeInput.value = type;
        this.toggleTenantPaidField(type);
        this.toggleDisableEmailToTenant(type);
        this.toggleExpiresInField(type);
        this.updateHeader(type);
        this.updateDescription(type);
        this.updateSubmitButton(type);

        offerTypeOptions.forEach((option) => option.classList.remove("selected"));
        option.classList.add("selected");
      });
    });
  }

  initializeListingSection() {
    const listingsSearchInput = document.getElementById("offer_listing");
    const channelSelection = document.getElementById("offer_channel");
    this.listingsFetchTimer = setInterval(() => {
      const term = listingsSearchInput.getAttribute("data-term");
      const newTerm = listingsSearchInput.value;

      const channelId = listingsSearchInput.getAttribute("data-channelid");
      let newChannelId = "0";
      if (channelSelection) {
        newChannelId = channelSelection.value;
      }

      if (term !== newTerm || channelId !== newChannelId) {
        const searchUrl = listingsSearchInput.getAttribute("data-url");
        const listingIdContainer = document.getElementById("offer_listing_id");
        let listingId = listingIdContainer.value;

        listingsSearchInput.setAttribute("data-term", newTerm);
        listingsSearchInput.setAttribute("data-channelid", newChannelId);

        if (newChannelId == "") {
          $(this.channelHintTarget).show();
        } else {
          $(this.channelHintTarget).hide();
        }

        fetch(`${searchUrl}?term=${newTerm}&listing_id=${listingId}&channel_id=${newChannelId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": getCsrfToken(),
          },
        })
          .then((response) => response.json())
          .then((data) => {
            this.renderListingsList(data);
          });
      }
    }, 700);
  }

  initializeControls() {
    const sendBtn = document.querySelector("#offer_form .send-btn");
    sendBtn.addEventListener("click", () => {
      this.sendOffer(sendBtn);
    });

    $(this.modalTarget).on("hidden.bs.modal", () => {
      clearTimeout(this.listingsFetchTimer);
      clearTimeout(this.validationTimer);
      clearTimeout(this.nextStepTimer);
      this.modalTarget.remove();
    });
  }

  startValidationMonitor() {
    const that = this;
    this.validationTimer = setInterval(() => {
      document
        .querySelectorAll(
          "#offer_type_section, #listing_section, #rent_section, #terms_section, #message_section, #concept_section, #channel_section, #unit_section"
        )
        .forEach((section) => {
          that.updateSectionIndicator(section);
        });
    }, 300);
  }

  renderListingsList({ listings, meta: { current_page, per_page, total_entries } }) {
    const listingsContainer = document.getElementById("listings_list");
    const loadMoreContainer = document.getElementById("listings_load_more");

    if (Number(current_page) === 1) {
      listingsContainer.innerHTML = "";
    }
    loadMoreContainer.innerHTML = "";

    listings.forEach((listing) => {
      const listingLine = this.buildListingLine(listing);
      listingsContainer.append(listingLine);
    });

    const remainingCount = total_entries - current_page * per_page;
    if (remainingCount > 0) {
      const loadMoreButton = document.createElement("button");
      loadMoreButton.setAttribute("data-action", "offers#loadMoreListings");
      loadMoreButton.setAttribute("data-next-page", current_page + 1);
      loadMoreButton.className =
        "bg-light-green inline-block w-full rounded py-3 text-center text-sm font-extrabold text-white mt-[16px]";
      loadMoreButton.innerHTML = `${this.loadMoreText} (+${remainingCount})`;

      loadMoreContainer.append(loadMoreButton);
    }
    this.updateOfferListingId();
  }

  loadMoreListings(event) {
    event.preventDefault();
    const nextPage = event.currentTarget.getAttribute("data-next-page");

    const listingsSearchInput = document.getElementById("offer_listing");
    const searchUrl = listingsSearchInput.getAttribute("data-url");

    const term = listingsSearchInput.getAttribute("data-term");
    const channelId = listingsSearchInput.getAttribute("data-channelid");

    const listingIdContainer = document.getElementById("offer_listing_id");
    let listingId = listingIdContainer.value;

    fetch(`${searchUrl}?term=${term}&listing_id=${listingId}&channel_id=${channelId}&page=${nextPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCsrfToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.renderListingsList(data);
      });
  }

  buildListingLine(listing) {
    const listingLine = document.createElement("div");
    listingLine.classList.add("listing-line");
    listingLine.setAttribute("data-listing-id", listing.id);
    if (listing.cancellation_policy) {
      listingLine.setAttribute("data-listing-cancellation-policy", listing.cancellation_policy);
    }

    const listingInfo = document.createElement("div");
    listingInfo.classList.add("listing-info");

    const listingTitle = document.createElement("span");
    listingTitle.classList.add("listing-title");
    listingTitle.innerHTML = listing.title;
    listingInfo.append(listingTitle);

    const listingDescription = document.createElement("span");
    listingDescription.classList.add("listing-description");
    listingDescription.innerHTML = listing.description;
    listingInfo.append(listingDescription);

    const listingImage = document.createElement("div");
    listingImage.classList.add("listing-image");
    listingImage.style["display"] = "inline-block";
    if (listing.image_url) {
      listingImage.style["background"] = "url(" + listing.image_url + ")";
      listingImage.style["background-size"] = "contain";
    }

    listingLine.append(listingImage);
    listingLine.append(listingInfo);

    listingLine.addEventListener("click", () => {
      if (listingLine.classList.contains("selected")) return;

      this.selectListing(listingLine);
    });

    return listingLine;
  }

  selectListing(listingLine) {
    const listingRemoveBtn = document.createElement("i");
    listingRemoveBtn.classList.add("icon-close-light");
    listingRemoveBtn.classList.add("listing-remove");
    listingRemoveBtn.addEventListener("click", (event) => {
      event.stopPropagation();
      listingRemoveBtn.remove();
      listingLine.classList.remove("selected");
      this.updateOfferListingId();
    });

    const cancellationPolicy =
      listingLine.getAttribute("data-listing-cancellation-policy") || this.defaultCancellationPolicy;
    $("#offer_cancellation_policy").val(cancellationPolicy).trigger("change");

    document.querySelectorAll("#listings_list .listing-line.selected").forEach((line) => {
      line.classList.remove("selected");
      line.querySelector("i.listing-remove").remove();
    });
    listingLine.classList.add("selected");
    listingLine.append(listingRemoveBtn);
    this.updateOfferListingId();

    clearTimeout(this.nextStepTimer);
    this.nextStepTimer = setTimeout(() => {
      document.querySelector("#rent_link").click();
    }, 500);
  }

  updateOfferListingId() {
    const listingIdContainer = document.getElementById("offer_listing_id");
    const selectedListingLine = document.querySelector("#listings_list .listing-line.selected");

    this.dispatch("listingChanged");

    if (selectedListingLine) {
      const listingId = selectedListingLine.getAttribute("data-listing-id");

      listingIdContainer.value = listingId;
      this.dateRangeTarget.dataset.dateRangeAvailabilityUrl = this.availabilityUrlTemplate.replace(
        "listingId",
        listingId
      );
      this.dateRangeTarget.dataset.dateRangeCheckAvailabilityUrl = this.checkAvailabilityUrlTemplate.replace(
        "listingId",
        listingId
      );

      this.fromTarget.disabled = false;
      this.toTarget.disabled = false;
      $(this.dateHintTarget).hide();
    } else {
      listingIdContainer.value = null;

      this.fromTarget.disabled = true;
      this.toTarget.disabled = true;
      $(this.dateHintTarget).show();
    }

    this.fromTarget.value = "";
    this.toTarget.value = "";
    this.onPriceOptionsChanged();
  }

  onPriceChanged(event) {
    const startDateStr = this.fromTarget.value;
    const endDateStr = this.toTarget.value;
    const listingId = document.querySelector("#offer_listing_id").value;

    let halfDayInSeconds = 0.5 * 24 * 3600;
    let startUTCTimestamp = moment(startDateStr, this.dateFormat).toDate().getTime() / 1000 + halfDayInSeconds;
    let endUTCTimestamp = moment(endDateStr, this.dateFormat).toDate().getTime() / 1000 + halfDayInSeconds;

    document.querySelector("#offer_rent_total").value = event.detail.value;
    this.refreshPrice(startUTCTimestamp, endUTCTimestamp, listingId, event.detail.value);
  }

  onPriceOptionsChanged() {
    const startDateStr = this.fromTarget.value;
    const endDateStr = this.toTarget.value;
    const listingId = document.querySelector("#offer_listing_id").value;

    if (startDateStr === "" || endDateStr === "" || listingId === "") {
      this.priceTarget.innerHTML = "";
      return;
    }

    let halfDayInSeconds = 0.5 * 24 * 3600;
    let startUTCTimestamp = moment(startDateStr, this.dateFormat).toDate().getTime() / 1000 + halfDayInSeconds;
    let endUTCTimestamp = moment(endDateStr, this.dateFormat).toDate().getTime() / 1000 + halfDayInSeconds;

    this.refreshPrice(startUTCTimestamp, endUTCTimestamp, listingId, null);
  }

  refreshPrice(start, end, listingId, rent) {
    const rentStr = rent == null ? "" : rent;
    const displayDiscountFlag = this.modalType == "offer";

    fetch(
      `${this.priceUrl}?listing_id=${listingId}&start=${start}&end=${end}&rent_total=${rentStr}&display_discount_flag=${displayDiscountFlag}`
    )
      .then((response) => response.text())
      .then((html) => {
        if (this.fromTarget.value === "" || this.toTarget.value === "") {
          return;
        }
        this.priceTarget.innerHTML = html;
        this.priceTarget.style.display = "block";
      });
  }

  updateSectionIndicator(section) {
    const validatedInputs = Array.from(section.querySelectorAll('[data-validate="true"]'));
    const filled = validatedInputs.every((input) => {
      return !!input.value;
    });
    const sectionHeader = document.querySelector(`.section__header[data-target="#${section.getAttribute("id")}"]`);
    if (filled) {
      section.querySelectorAll(".modal__offer .input__error").forEach((errorHolder) => {
        errorHolder.innerHTML = "";
        errorHolder.classList.add("is-hidden");
      });
      sectionHeader.classList.remove("invalid");
      sectionHeader.classList.add("filled");
    } else {
      sectionHeader.classList.remove("filled");
    }
  }

  toggleTenantPaidField(type) {
    const tenantPaidField = document.querySelector(".tenant_paid_field");

    if (!tenantPaidField) {
      return;
    }

    if (type === "booking") {
      tenantPaidField.classList.remove("is-hidden");
    } else {
      tenantPaidField.classList.add("is-hidden");
    }
  }

  toggleDisableEmailToTenant(type) {
    const disableEmailToTenantField = document.querySelector(".disable_email_to_tenant_field");

    if (!disableEmailToTenantField) {
      return;
    }

    if (type === "booking") {
      disableEmailToTenantField.classList.remove("is-hidden");
    } else {
      disableEmailToTenantField.classList.add("is-hidden");
    }
  }

  toggleExpiresInField(type) {
    const expiresInField = document.querySelector(".expires_in_field");

    if (!expiresInField) {
      return;
    }

    if (type === "booking") {
      expiresInField.classList.add("is-hidden");
    } else {
      expiresInField.classList.remove("is-hidden");
    }
  }

  updateHeader(type) {
    const headerTitle = document.querySelector("#offer_modal .modal-title");
    headerTitle.innerText = t(`offers.title.${type}`, this.locale);
  }

  updateDescription(type) {
    const description = document.querySelector("#offer_modal .modal-description");
    description.innerText = t(`offers.descriptions.${type}`, this.locale);
  }

  updateSubmitButton(type) {
    const sendBtn = document.querySelector("#offer_form .send-btn");
    sendBtn.innerText = t(`offers.controls.${type}`, this.locale);
  }

  sendOffer(sendBtn) {
    sendBtn.disabled = true;

    const form = document.getElementById("offer_form");
    const method = form.getAttribute("data-method");
    const action = form.getAttribute("data-action");
    const userId = document.querySelector("#offer_user_id").value;
    const listingId = document.querySelector("#offer_listing_id").value;
    const message = document.querySelector("#offer_offer_message").value;
    const overallLook = document.querySelector("#offer_overall_look").value;
    const from = document.querySelector("#offer_from").value;
    const to = document.querySelector("#offer_to").value;
    let disableDiscounts = false;
    if (document.querySelector("#offer_disable_discounts")) {
      disableDiscounts = document.querySelector("#offer_disable_discounts").checked;
    }
    const rentTotal = document.querySelector("#offer_rent_total").value;
    const cancellationPolicy = document.querySelector("#offer_cancellation_policy").value;
    const otherTerms = document.querySelector("#offer_other_terms").value;
    const offerExpiresInInput = document.querySelector("#offer_offer_expires_in");
    const tenantPaidInput = document.querySelector("#offer_tenant_paid");
    const disableEmailToTenantInput = document.querySelector("#offer_disable_email_to_tenant");
    const industry = Array.from(document.querySelectorAll("#offer_industry option:checked")).map(
      (option) => option.value
    );

    let channelId = null;
    const channelInput = document.querySelector("#offer_channel");
    if (channelInput) {
      channelId = channelInput.value;
    }

    let unitId = null;
    const unitInput = document.querySelector("#offer_unit");
    if (unitInput) {
      unitId = unitInput.value;
    }

    let type = this.modalType;
    let offerExpiresIn = null;
    let tenantPaid = null;
    let disableEmailToTenant = false;

    if (this.differentTypesAllowed) {
      type = document.querySelector("#offer_type").value;
    }

    if (tenantPaidInput && type === "booking") {
      tenantPaid = true;
    }

    if (offerExpiresInInput && type === "offer") {
      offerExpiresIn = offerExpiresInInput.value;
    }

    if (disableEmailToTenantInput && type === "booking") {
      disableEmailToTenant = disableEmailToTenantInput.checked;
    }

    $.ajax({
      url: action,
      type: method,
      data: {
        offer: {
          user_id: userId,
          type: type,
          listing_id: listingId,
          from: from,
          to: to,
          rent_total: rentTotal,
          tenant_paid: tenantPaid,
          disable_email_to_tenant: disableEmailToTenant,
          cancellation_policy: cancellationPolicy,
          offer_expires_in: offerExpiresIn,
          other_terms: otherTerms,
          offer_message: message,
          industry: industry,
          overall_look: overallLook,
          disable_discounts: disableDiscounts,
          channel_id: channelId,
          ...(unitId !== null && { landlord_action_unit_id: unitId }),
        },
      },
    })
      .done((data) => {
        if (data.errors) {
          this.showErrorNotifications(data.errors);
          sendBtn.disabled = false;
        } else {
          window.location.reload();
        }
      })
      .fail(() => {
        $(this.modalTarget).modal("hide");
      });
  }

  showErrorNotifications(errors) {
    document.querySelectorAll(".modal__offer .input__error").forEach((errorHolder) => {
      errorHolder.innerHTML = "";
      errorHolder.classList.add("is-hidden");
    });

    document.querySelectorAll(".modal__offer .accordion-section").forEach((section) => {
      section.classList.remove("invalid");
    });

    Object.entries(errors).forEach((error) => {
      let fieldName = error[0];
      const errorMessage = error[1];

      if (fieldName === "dates") {
        document.querySelector("#offer_from").value = null;
        document.querySelector("#offer_to").value = null;
      }

      const errorHolder = document.getElementById(`offer_${fieldName}_error`);
      const section = document.querySelector(`.accordion-section.${errorHolder.getAttribute("data-section")}`);
      section.classList.add("invalid");
      errorHolder.classList.remove("is-hidden");
      errorHolder.innerHTML = errorMessage;
    });
  }

  get loadMoreText() {
    return this.data.get("loadMoreText") || "Load more";
  }

  get locale() {
    return this.data.get("locale") || "en";
  }

  get priceUrl() {
    return this.data.get("priceUrl");
  }

  get modalType() {
    return this.data.get("modalType");
  }

  get dateFormat() {
    return this.data.get("dateFormat");
  }

  get differentTypesAllowed() {
    return this.data.get("differentTypesAllowed") === "true";
  }

  get checkAvailabilityUrlTemplate() {
    return this.data.get("checkAvailabilityUrlTemplate");
  }

  get availabilityUrlTemplate() {
    return this.data.get("availabilityUrlTemplate");
  }

  get defaultCancellationPolicy() {
    return this.data.get("defaultCancellationPolicy");
  }
}
